/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_X9VFcIRDB",
    "aws_user_pools_web_client_id": "6j8nu09cdo85vrt155lr3ioqbh",
    "oauth": {
        "domain": "oerol-webapp-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://service.oerol.nl/account/external-authentication",
        "redirectSignOut": "https://oerol.nl/nl/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
