import React, { FC } from "react";
import styled, { css } from "styled-components";

type variant = "dot" | "bracket" | "line";
type background = "pattern" | true | false;

export interface FrameProps {
    variant?: variant;
    tl?: boolean;
    tr?: boolean;
    br?: boolean;
    bl?: boolean;
    background?: background;
    className?: string;
}

const Frame: FC<FrameProps> = React.memo(({
    className,
    background = true,
    variant = "bracket",
    tl = false,
    tr = false,
    br = false,
    bl = false,
    children,
    ...props
}) => (
    <StyledDiv className={className} background={background} {...props}>
        <BlurredSvg>
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                opacity="0.25"
            />
        </BlurredSvg>
        <svg>
            <style>
                {`
                .bracket {
                    stroke: currentColor;
                    stroke-width: 2.2;
                    fill: none;
                }
                .dot {
                    stroke: currentColor;
                    stroke-width: 1.2;
                    fill: white;
                    width: 4px;
                    height: 4px;
                }

                .dot--empty {
                    fill: none;
                }
                `}
            </style>
            {/* bracket */}
            <symbol id="bracketTL" width="10" height="10px">
                <polyline points="0,10 0,0 10,0" className="bracket" />
            </symbol>
            <symbol id="bracketTR" width="10" height="10px">
                <polyline points="0,0 10,0 10,10" className="bracket" />
            </symbol>
            <symbol id="bracketBL" width="10" height="10px">
                <polyline points="0,0 0,10 10,10" className="bracket" />
            </symbol>
            <symbol id="bracketBR" width="10" height="10px">
                <polyline points="0,10 10,10 10,0" className="bracket" />
            </symbol>
            {/* dot */}
            <symbol id="dotTL" width="10" height="10px">
                <rect x="0.5" y="0.5" className="dot" />
            </symbol>
            <symbol id="dotTR" width="10" height="10px">
                <rect x="5.5" y="0.5" className="dot dot--empty" />
            </symbol>
            <symbol id="dotBL" width="10" height="10px">
                <rect x="0.5" y="5.5" className="dot dot--empty" />
            </symbol>
            <symbol id="dotBR" width="10" height="10px">
                <rect x="5.5" y="5.5" className="dot dot--empty" />
            </symbol>
            {tl && <use xlinkHref={`#${variant}TL`} x="0" y="0" />}
            {tr && (
                <use
                    xlinkHref={`#${variant}TR`}
                    x="100%"
                    y="0"
                    transform="translate(-10 0)"
                />
            )}
            {br && (
                <use
                    xlinkHref={`#${variant}BR`}
                    x="100%"
                    y="100%"
                    transform="translate(-10 -10)"
                />
            )}
            {bl && (
                <use
                    xlinkHref={`#${variant}BL`}
                    x="0"
                    y="100%"
                    transform="translate(0 -10)"
                />
            )}
            {variant === "line" && (
                <>
                    <line x1="0" y1="25%" x2="0" y2="75%" className="bracket" />
                    <line
                        x1="100%"
                        y1="25%"
                        x2="100%"
                        y2="75%"
                        className="bracket"
                    />
                </>
            )}
        </svg>
        <>{children}</>
    </StyledDiv>
));

const patternBackground = css`
    background-color: rgba(255, 255, 255, 0.2);
    background-image: url('${require('../../../images/texture.png')}');
    background-size: contain;
`;

const StyledDiv = styled.div<{ background: background }>`
    position: relative;
    color: white;

    &::before {
        content: "";
        display: block;
        ${({ background }) =>
            background === "pattern"
                ? patternBackground
                : background === true
                ? "background: rgba(255, 255, 255, 0.12);"
                : null}
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        backdrop-filter: blur(4px);
    }

    > svg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
`;

/**
 * blurred svg with mediaquery to detect safari. Safari struggles with filters so we disable them for now.
 */
const BlurredSvg = styled.svg`
    filter: blur(0.5px);

    @media not all and (min-resolution: 0.001dpcm) { 
        filter: none;
    }
`;

export default Frame;
