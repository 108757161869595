import React, { Component, ErrorInfo } from 'react';
import { GlobalStyles } from '../globalStyling';
import Helmet from 'react-helmet';
import * as Sentry from '@sentry/browser';
import InternalError from '../pages/500';
import { WindowLocation } from '@reach/router';
import Background from './ds/layout/Background';
import * as Bowser from 'bowser';
import OutdatedBrowser from './OutdatedBrowser';
import isBrowser from '../utils/isBrowser';
import configureAmplify from '../services/configureAmplify';
import AuthProvider from './AuthProvider';

/**
 * Configure Amplify
 */
configureAmplify();

interface Props {
    location: WindowLocation;
}

class App extends Component<Props> {
    state = {
        hasError: false,
        error: null,
    };

    componentDidMount() {
        Sentry.init({
            dsn: 'https://cc44823f306649eda57fc46584713a98@sentry.oberon.nl/38',
            enabled: process.env.NODE_ENV === 'production',
        });
    }

    componentDidUpdate({ location }: Props) {}

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.configureScope((scope) => {
            scope.setExtra('stack', errorInfo.componentStack);
        });
        Sentry.captureException(error);

        this.setState({
            hasError: true,
            error,
        });
    }

    render() {
        let { children } = this.props;

        if (isBrowser) {
            const browser = Bowser.getParser(window.navigator.userAgent);
            /**
             * fix mobile firefox check wher bowser supports it
             */
            const isValidBrowser =
                browser.getUA().indexOf('FxiOS') > 0 ||
                browser.satisfies({
                    chrome: '>=70',
                    firefox: '>=60',
                    opera: '>=50',
                    edge: '>=15',
                    'internet explorer': '>11',
                    safari: '>=12',
                });

            if (!isValidBrowser) {
                children = <OutdatedBrowser />;
            }
        }

        if (this.state.hasError) {
            children = <InternalError error={this.state.error} />;
        }

        return (
            <>
                <Helmet>
                    <html lang="nl" />
                    <title>Het Imaginaire Eiland / Imagine Your Island | Oerol</title>
                </Helmet>
                <Background
                    imgSrc={[
                        require('../images/background1.jpg'),
                        require('../images/background2.jpg'),
                        require('../images/background3.jpg'),
                    ]}
                />
                <GlobalStyles />
                <AuthProvider>{children}</AuthProvider>
            </>
        );
    }
}

export default App;
