import React from 'react';
import App from './src/components/App';
import objectFitImages from 'object-fit-images';

// polyfill time 😎
if (typeof window !== 'undefined') {
    if (!('Symbol' in window)) {
        require('symbol-es6');
    }

    if (!('IntersectionObserver' in window)) {
        require('intersection-observer-polyfill/dist/IntersectionObserver.global');
    }
}

// require('@babel/polyfill');

objectFitImages(null, { watchMQ: true });

// prevent site from getting framed
if (typeof window !== 'undefined' && window.top.location !== window.self.location) {
    window.top.location.assign(window.self.location.href);
}

export const wrapPageElement = ({ props: { pageContext, location }, element }) => {
    const cameFrom = (location.state && 'cameFrom' in location.state) ? location.state.cameFrom : null;
    if (cameFrom) {
        // don't render the full layout
        return <App location={location} language={pageContext.language} pageContext={pageContext}>{element}</App>;
    }
    return (
        <App
            pageContext={pageContext}
            language={pageContext.language}
            location={location}
        >
            {element}
        </App>
    );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) =>
    (!location.state) || location.state.restoreScroll !== false;
