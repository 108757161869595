// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-page-show-tsx": () => import("./../src/components/PageShow.tsx" /* webpackChunkName: "component---src-components-page-show-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

