import React, { FC } from "react";
import styled from "styled-components";
import { Action } from "../primitive/typography";
import cssGlitch from "../../../utils/cssGlitch";

interface Props extends React.HTMLProps<HTMLAnchorElement | HTMLButtonElement> {
    label: string;
    className?: string;
}

const ButtonText: FC<Props> = React.memo(
    ({ children, label, onClick, disabled, as, ref, ...props }) => (
        <StyledA
            isDisabled={!!disabled}
            onClick={disabled ? undefined : onClick}
            {...props}
        >
            <Label title={label} as="span">
                {label}
            </Label>
        </StyledA>
    )
);

const StyledA = styled.a<{ isDisabled: boolean }>`
    display: inline-block;
    border-bottom: 1px solid white;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    ${({ isDisabled }) =>
        isDisabled &&
        `
        opacity: 0.3;
        cursor: not-allowed;
    `}
`;

const Label = styled(Action)`
    ${cssGlitch}
`;

export default ButtonText;
