import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import AuthContext, { Auth as AuthProps, AuthTickets, defaultAuth } from '../contexts/AuthContext';
import cookie from 'js-cookie';
import { getUserAccess as getUserAccessUrl } from '../constants/urls';

const AuthProvider: React.FC = ({ children, ...props }) => {
    const getUserAccess = useCallback(async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const token = currentUser?.signInUserSession.idToken.jwtToken;

            if (token) {
                const data = await fetch(getUserAccessUrl(), {
                    headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: token },
                    method: 'GET',
                    cache: 'no-store',
                });

                if (data.ok) {
                    const tickets = (await data.json()) as number[];
                    return {
                        tickets,
                        hasFestivalTicket: process.env.GATSBY_FESTIVAL_TICKET && tickets.indexOf(Number(process.env.GATSBY_FESTIVAL_TICKET)) >= 0,
                    } as AuthTickets;
                }
            }
        } catch (e) {
            signOut();
            console.log(e);
        }
        return undefined;
    }, []);

    const signIn = useCallback(async (username: string, password: string) => {
        await Auth.signIn(username, password);
        const currentUser = await Auth.currentAuthenticatedUser();

        const userTickets = await getUserAccess();

        setAuth({
            ...auth,
            isLoggedIn: true,
            currentUser,
            userTickets,
        });
    }, []);

    const signOut = useCallback(async () => {
        await Auth.signOut();
        cookie.remove('fionaCode');
        setAuth({
            ...defaultAuth,
            isLoggedIn: false,
            signIn,
            signOut,
            getUserAccess,
        });
    }, []);

    const [auth, setAuth] = useState<AuthProps>({
        ...defaultAuth,
        auth: Auth,
        signIn,
        signOut,
        getUserAccess,
    });

    useEffect(() => {
        const amILoggedIn = async () => {
            let isLoggedIn: boolean | undefined;
            let currentUser: AuthProps['currentUser'];
            let userTickets: AuthTickets | undefined;
            try {
                await Auth.currentSession();
                currentUser = await Auth.currentAuthenticatedUser();
                isLoggedIn = true;
                userTickets = await getUserAccess();
            } catch {
                isLoggedIn = false;
            }

            setAuth({
                ...auth,
                isLoggedIn,
                currentUser,
                userTickets,
            });
        };

        amILoggedIn();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                auth,
                set: setAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
