import styled from "styled-components";
import { cover } from "polished";
import React, { useState, FC } from "react";
import { DateTime } from "luxon";
import { useEffect } from "react";

interface Props {
    imgSrc: string[];
}

const Background: FC<Props> = ({ imgSrc }) => {
    const [src, setSrc] = useState<string>(imgSrc[0]);

    useEffect(() => {
        const pickBackground = () => {
            const now = DateTime.fromObject({ zone: "Europe/Amsterdam" });
            let currentMinutes = now.hour * 60 + now.minute;
            let newSrc;

            if (imgSrc?.length > 0) {
                newSrc = imgSrc[0];
            }

            if (imgSrc?.length > 1 && currentMinutes >= 1050) {
                newSrc = imgSrc[1];
            }

            if (imgSrc?.length > 2 && (currentMinutes >= 1320 || currentMinutes < 420)) {
                newSrc = imgSrc[2];
            }

            if (newSrc && newSrc !== src) {
                setSrc(newSrc);
            }
        };
        pickBackground();
        const interval = setInterval(pickBackground, 1000 * 5 * 60);

        return () => {
            clearInterval(interval);
        };
    }, [src]);

    return <BackgroundStyled imgSrc={src} />;
};

const BackgroundStyled = styled.div<{ imgSrc: string }>`
    ${cover()}
    background-image: url(${({ imgSrc }) => imgSrc});
    background-size: cover;
    background-position: 50% 50%;
    z-index: -1;

    &::before {
        content: "";
        display: block;
        ${cover()}
        opacity: 0.5;
        background-color: black;
        background-image: url(${require("../../../images/pattern_lines.svg")});
        background-position: 50% 50%;

        @media (max-width: 580px) {
            opacity: 0.8;
        }
    }
`;

export default Background;