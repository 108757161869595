import { css } from "styled-components";

export default css`
    /* animation: glitch 1s linear infinite; */
    /* will-change: transform; */
`;

// /** Glitch effect */
// @keyframes glitch {
//     2%,
//     64% {
//         transform: translate(0.02em, 0) skew(0deg);
//     }
//     4%,
//     60% {
//         transform: translate(-0.02em, 0) skew(0deg);
//     }
//     62% {
//         transform: translate(0, 0) skew(5deg);
//     }
// }

// &:before,
// &:after {
//     content: attr(title);
//     position: absolute;
//     left: 0;
//     will-change: transform;
// }

// &:before {
//     /* animation: glitchTop 1s linear infinite; */
//     clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
//     -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
// }

// @keyframes glitchTop {
//     2%,
//     64% {
//         transform: translate(0.02em, -0.02em);
//     }
//     4%,
//     60% {
//         transform: translate(-0.02em, 0.02em);
//     }
//     62% {
//         transform: translate(0.13em, -0.01em) skew(-13deg);
//     }
// }

// &:after {
//     /* animation: glitchBotom 1.5s linear infinite; */
//     clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
//     -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
// }

// @keyframes glitchBotom {
//     2%,
//     64% {
//         transform: translate(-0.02em, 0);
//     }
//     4%,
//     60% {
//         transform: translate(-0.02em, 0);
//     }
//     62% {
//         transform: translate(-0.22em, 0.05em) skew(21deg);
//     }
// }