import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Frame, { FrameProps } from "../layout/Frame";

interface Props extends FrameProps {
    title?: ReactNode;
};

const BlockWithTitle: FC<Props> = React.memo(({ title, children, ...props }) => (
    <StyledFrame tl bl variant="dot" {...props}>
        {title && <TitleBlock background={false}>{title}</TitleBlock>}
        <InnerBlock>{children}</InnerBlock>
    </StyledFrame>
));

const StyledFrame = styled(Frame)`
    display: block;
    position: relative;
`;

export const TitleBlock = styled(Frame).attrs(() => ({
    tl: true,
    background: false,
    variant: 'dot',
}))`
    display: inline-flex;
    padding: 1.2rem 1.6rem;
    height: 4rem;
    box-sizing: border-box;
    align-items: center;
`;

export const InnerBlock = styled.div`
    padding: 2.4rem;
    z-index: 1;
    position: relative;

    & > ${TitleBlock} {
        margin: 3.2rem 0 3.2rem -2.4rem;
    }
`;

export default BlockWithTitle;
