import { Auth as AmplifyAuth } from 'aws-amplify';
import { createContext } from 'react';

export interface AuthTickets {
    tickets: number[];
    hasFestivalTicket: boolean;
}

export interface Auth {
    auth?: typeof AmplifyAuth;
    currentUser?: any;
    signIn?: (username: string, password: string) => void;
    signOut?: () => void;
    getUserAccess?: () => Promise<AuthTickets | undefined>;
    isLoggedIn?: boolean;
    userTickets?: AuthTickets;
}
export const defaultAuth: Auth = {
    auth: undefined,
    currentUser: undefined,
    signIn: undefined,
    signOut: undefined,
    getUserAccess: undefined,
    isLoggedIn: undefined,
};
const AuthContext = createContext({
    auth: defaultAuth,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set: (auth: Auth) => {
        // no-op by default
    },
});

export default AuthContext;
