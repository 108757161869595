import Amplify from 'aws-amplify';
// @ts-ignore
import awsconfig from '../aws-exports';

const configureAmplify = () => {
    Amplify.configure({
        ...awsconfig,
        cookieStorage: {
            domain: process.env.GATSBY_ROOT_URL ?? 'localhost',
            secure: false,
            path: '/',
            expires: 0.04,
            sameSite: 'lax',
        },
    });
};

export default configureAmplify;
