import styled, { css } from "styled-components";
import cssCalcBetweenViewport from "../../../utils/cssCalcBetweenViewport";

// Title

export type TitleVariant = "large" | "regular" | "small";

const headingCss = css`
    margin: 0;
    padding: 0;
    font-family: "DMMono", monospace;
    font-weight: 500;
`;

export const titleLargeCss = css`
    ${headingCss}
    font-size: ${cssCalcBetweenViewport(16, 28)};
    line-height: ${cssCalcBetweenViewport(20, 36)};
`;

export const titleRegularCss = css`
    ${headingCss}
    font-size: ${cssCalcBetweenViewport(12, 14)};
    line-height: ${cssCalcBetweenViewport(14, 16)};
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;

export const titleSmallCss = css`
    ${headingCss}
    font-size: ${cssCalcBetweenViewport(11, 13)};
    line-height: ${cssCalcBetweenViewport(11, 13)};
    letter-spacing: 0em;
    text-transform: uppercase;
`;

const getTitleCss = (variant?: TitleVariant) => {
    switch (variant) {
        case "large":
            return titleLargeCss;
        case "small":
            return titleSmallCss;
        case "regular":
        default:
            return titleRegularCss;
    }
};

export const H1 = styled.h1<{ variant?: TitleVariant }>`
    ${({ variant }) => getTitleCss(variant)};
`;

export const H2 = styled.h2<{ variant?: TitleVariant }>`
    ${({ variant }) => getTitleCss(variant)};
`;

export const H3 = styled.h3<{ variant?: TitleVariant }>`
    ${({ variant }) => getTitleCss(variant)};
`;

export const H4 = styled.h4<{ variant?: TitleVariant }>`
    ${({ variant }) => getTitleCss(variant)};
`;

export const H5 = styled.h5<{ variant?: TitleVariant }>`
    ${({ variant }) => getTitleCss(variant)};
`;

// Body

export type BodyVariant = "regular" | "small" | "tiny" | "micro";

const bodyCss = css`
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
`;

export const bodyMicroCss = css`
    font-size: 1.0rem;
    line-height: 1.4rem;
`;

export const bodyTinyCss = css`
    font-size: 1.2rem;
    line-height: 1.6rem;
`;

const bodySmallCss = css`
    font-size: ${cssCalcBetweenViewport(12, 13)};
    line-height: ${cssCalcBetweenViewport(16, 18)};
`;

const bodyRegularCss = css`
    font-size: ${cssCalcBetweenViewport(13, 16)};
    line-height: ${cssCalcBetweenViewport(16, 20)};
`;

const getBodyCss = (variant?: BodyVariant) => {
    switch (variant) {
        case "micro":
            return bodyMicroCss;
        case "tiny":
            return bodyTinyCss;
        case "small":
            return bodySmallCss;
        case "regular":
        default:
            return bodyRegularCss;
    }
};

export const Body = styled.p<{ variant?: BodyVariant }>`
    ${bodyCss}
    ${({ variant }) => getBodyCss(variant)};
`;

// Action

export type ActionVariant = "regular" | "small";

export const actionCss = css`
    margin: 0;
    padding: 0;
    font-family: "DMMono", monospace;
    letter-spacing: 0.02em;
    font-weight: 500;
`;

const actionRegularCss = css`
    font-size: ${cssCalcBetweenViewport(13, 15)};
    line-height: ${cssCalcBetweenViewport(16, 20)};
`;

const actionSmallCss = css`
    font-size: ${cssCalcBetweenViewport(13, 15)};
    line-height: ${cssCalcBetweenViewport(16, 20)};
    font-size: 1.0rem;
    line-height: 1.4rem;
`;

const getActionCss = (variant?: ActionVariant) => {
    switch (variant) {
        case "small":
            return actionSmallCss;
        case "regular":
        default:
            return actionRegularCss;
    }
};

export const Action = styled.p<{ variant?: ActionVariant }>`
    ${actionCss}
    ${({ variant }) => getActionCss(variant)};
`;



export const CMSContent = styled.div`
    ${getBodyCss('regular')}

    h1, h2, h3, h4, h5 {
        ${getTitleCss('regular')}
    }

    a, a:visited {
        ${getActionCss('regular')}
    }

    > * + * {
        margin-top: 0.5em;
    }
`;