module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oerol 2020","short_name":"Oerol 2020","start_url":"/","background_color":"#ffffff","theme_color":"#333442","display":"standalone","icon":"src/images/icon.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"0fe48b8a0596ebfcb2d158c459902461"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-31214012-4","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WHTSV3W"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
