import { createGlobalStyle, css } from "styled-components";

const srcMono = (variant: string) => css`
    src: url('${require(`./fonts/DMMono-${variant}.ttf`)}') format('truetype');
`;

const srcSans = (variant: string) => css`
    src: url('${require(`./fonts/DMSans-${variant}.ttf`)}') format('truetype');
`;

export const GlobalStyles = createGlobalStyle`
    html {
        font-size: 10px;
        background: black;
        overscroll-behavior: none;
    }
    
    body {
        margin: 0;
        padding: 0;
        font-family: 'DMMono', Helvetica, sans-serif;
        height: 100vh;
        font-size: 14px;
        color: white;
    }
    
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
        
    a {
        color: currentColor;
    }

    p, h1, h2, h3, h4, h5, a {
        margin: 0;
        padding: 0;
    }

    @font-face {
        font-family: 'DMMono';
        ${srcMono("Regular")};
        font-weight: 400;
    }

    @font-face {
        font-family: 'DMMono';
        ${srcMono("Medium")};
        font-weight: 500;
    }
    
    @font-face {
        font-family: 'DMSans';
        ${srcSans("Regular")}; 
        font-weight: 400;
    }

    @font-face {
        font-family: 'DMSans';
        ${srcSans("Medium")}; 
        font-weight: 500;
    }
`;
